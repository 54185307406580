import './NavigationIconItem.scss';

import React from 'react';

type Props = {
  external?: boolean;
  title: string;
  subtitle?: string;
  link: string;
};

export const NavigationIconItem = ({ external, link, subtitle, title }: Props) => {
  const href = external ? link : `#${link}`;

  return (
    <a
      href={href}
      target={external ? '_blank' : '_self'}
      aria-label={`See ${title} feature`}
      className="dd-item d-flex flex-row m-1 p-2 align-items-center"
      rel="noreferrer">
      <div className="me-3 mx-2">
        <p className="a">{title}</p>
        {subtitle ? <span>{subtitle}</span> : ''}
      </div>
    </a>
  );
};
