import './NavigationMenu.scss';

import React from 'react';

import { BROCHURE_URL } from '../../../utils/constants';

import { NavigationIconItem } from '../../NavigationIconItem/NavigationIconItem';

export const NavigationMenu = () => {
  return (
    <div className="col-10 col-xl-4 align-items-center justify-content-end nav-mobile">
      <div className="dropdown d-flex">
        <button
          className="mobile-toggle dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-label="Toggle navigation menu window">
          <img className="mobile-menu-close" src="/images/icons/cross.svg" alt="Cross icon in white"></img>
          <img className="mobile-menu-toggle" src="/images/icons/menu.svg" alt="Icon with three horizontal lines"></img>
        </button>
        <div className="mobile-menu dropdown-menu dropdown-menu-end flex-column">
          <NavigationIconItem title="Features" link="features" />
          <NavigationIconItem title="FAQs" link="faqs" />
          <NavigationIconItem title="Download Brochure" link={BROCHURE_URL} external />
          <NavigationIconItem title="Contact" link="contact" />
          <NavigationIconItem title="I Want VIKIN" link="contact" />
        </div>
      </div>
    </div>
  );
};
